import Layout from "../../../layouts/Layout";
import logo from "../../../storage/logo.png";
import { useOrderGateway } from "../core/OrderGatewayProvider";

import OrderGatewayError from "./OrderGatewayError";
import OrderGatewaySuccess from "./OrderGatewaySuccess";

export default function WorkersCommitteePaymentPage() {
	const { order } = useOrderGateway();

	const { transactions, unpaid_advance_transaction } = order;

	if (!unpaid_advance_transaction && transactions.length === 0) {
		return <OrderGatewayError />;
	}

	/**
	 * If the advance payment transaction is paid, we need to redirect the user to the next step.
	 */
	if (!unpaid_advance_transaction && transactions.length > 0) {
		const transaction = transactions.find((transaction) => transaction.type === "advance-payment");

		if (transaction && transaction.is_paid) {
			return <OrderGatewaySuccess type="workers-committee" />;
		}
	}

	const { student } = order;
	const { page_request_uid } = unpaid_advance_transaction;

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px:4 md:px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<div className="px-4">
						<header className="absolute right-0 left-0 top-[-67.5px] w-full">
							<img src={logo} alt="logo" className="mx-auto" />
						</header>
					</div>
					<p className="font-light mb-4 px-4">
						שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
						<br />
						אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת "אקשן על גלגלים".
						<br />
						לידיעתכם, הרישום ושמירת המקום בקייטנה מותנים במילוי טופס הרשמה ובתשלום דמי רישום על סך 50 ש"ח (אשר במקרה של הודעת ביטול, שתתקבל בכתב החל מתאריך 10.6 ואילך, לא יוחזרו). לאחר מילוי טופס ההרשמה ותשלום דמי הרישום יש לבצע הרשמה ותשלום יתרת התשלום באמצעות ועד העובדים/ארגון אותו ציינתם בשלבי ההרשמה
						(כמפורט בתקנון אותו אישרתם בעת ביצוע ההרשמה).
						{/* <br />
						זו ההזדמנות להזכירכם לפרגן לחברים ולשתפם בחווית הקייטנה שלנו. אנחנו כאן בשבילכם לכל שאלה בטלפון - 054-4781055, או באתר www.funkid-k.com */}
					</p>
					{/* <p className="text-lg font-light mb-4">
						שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
						<br />
						אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת "אקשן על גלגלים".
					</p>
					<p>
						לידיעתכם, <strong className="font-bold">שמירת מקום ילדכם בקייטנה מותנה בתשלום דמי רישום.</strong>
						<br />
						בקישור המצ"ב ולאחר מכן רישום ותשלום באמצעות ועד העובדים/ארגון אותו ציינתם בטופס ההרשמה.
					</p> */}
					<div className="mt-8 lg:mt-12">
						<h2 className="text-xl font-semibold mb-2 block">תשלום דמי רישום</h2>
						<iframe title="AAG Payment" className="w-full aspect-[10/17] md:aspect-[16/12]" width="100%" src={`https://payments.payplus.co.il/${page_request_uid}`}></iframe>
					</div>
				</div>
			</div>
		</Layout>
	);
}
