import React from "react";

import background from "../storage/background-blur.png";

const Layout = (props) => {
	return (
		<div className="font-nunito bg-black min-h-screen h-auto w-full bg-no-repeat bg-cover" style={{ backgroundImage: `url('${background}')` }}>
			<div className="flex justify-center align-middle min-h-screen h-auto pt-12 pb-8 px-4 md:pt-0 md:pb-0 md:px-0 bg-black bg-opacity-30">{props.children}</div>
		</div>
	);
};

export default Layout;
