import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import RegistrationForm from "./components/RegistrationForm/RegistrationForm";
import OrderGateway from "./components/OrderGateway/OrderGateway";

/**
 * SASS
 */
import "./sass/registration.scss";

function App() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				{/* <Routes>
					<Route path="/" element={<Registration />} />
					<Route path="/order-received/:orderKey" element={<OrderReceived />} />
					<Route path="/checkout/:orderKey/transaction/:transactionKey" element={<Checkout />} />
					<Route path="/order/:orderKey/choose" element={<OrderChoosePayment />} />
					<Route path="/order/:orderKey/thankyou" element={<OrderThankyou />} />
					<Route path="/workers-committee/:orderKey" element={<WorkersCommitteeThankyou />} />
				</Routes> */}
				<Routes>
					<Route path="/" element={<RegistrationForm />} />
					<Route path="order/:orderKey" element={<OrderGateway />} />
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
