import { useRegistrationForm } from "./core/RegistrationFormProvider";

export const RegistrationFormIndicator = () => {
	const { registerData } = useRegistrationForm();
	const { step } = registerData || {};

	const steps = [
		{ label: "פרטי הילד", number: "01" },
		{ label: "יצירת קשר", number: "02" },
		{ label: "איסוף", number: "03" },
		{ label: "הצהרה", number: "04" },
		{ label: "סיכום", number: "05" },
	];

	return (
		<div className="current-step-indicator">
			{steps.map((option, index) => {
				let classes = "current-step-indicator__step";

				index += 2;

				if (index < step) {
					classes += " current-step-indicator__step--done";
				} else if (index === step) {
					classes += " current-step-indicator__step--current";
				}

				return (
					<div key={index} className={classes}>
						<span>{option.label}</span>
						<div className="current-step-indicator__step__number">
							<span>{option.number}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};
