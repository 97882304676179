import React, { useEffect } from "react";
import { useRegistrationForm } from "../core/RegistrationFormProvider";

import Button from "../../Button";
import logo from "../../../storage/logo.png";
import { useSearchParams } from "react-router-dom";

export default function SelectCampTab() {
	const { isLoading, registerData, setRegisterData, camps } = useRegistrationForm();
	const { selectedCamp } = registerData || {};

	const [searchParams, setSearchParams] = useSearchParams();

	console.log("Camps:", camps);

	let discountPriceDate = selectedCamp?.discount_price_ends_at;

	if (discountPriceDate) {
		discountPriceDate = new Date(discountPriceDate);
		discountPriceDate = [discountPriceDate.getDate(), discountPriceDate.getMonth() + 1, discountPriceDate.getFullYear()].join("/");
	}

	const campParam = searchParams.get("camp");

	/**
	 * The URL might contain a camp parameter, if it does and the selectedCamp is not set, we should set it
	 */
	useEffect(() => {
		console.log("useEffect :: campParam", campParam);
		if (!campParam) {
			return;
		}

		const camp = camps?.find((camp) => camp.id === parseInt(campParam));

		if (camp) {
			setRegisterData({
				selectedCamp: camp,
				step: 2,
				canChangeCamp: false,
			});
		}
	}, [campParam, camps]);

	return (
		<div className="max-w-md w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-opacity-30 bg-white text-white relative">
			<header className="absolute top-[-67.5px] w-full">
				<img src={logo} alt="logo" className="mx-auto" />
			</header>
			<strong className="text-2xl font-semibold mb-2 block">בחר קייטנה</strong>
			<p className="text-md font-light mb-4">
				קהל לקוחותינו,
				<br />
				הקייטנה עובדת לפי מתווה משרד הבריאות והחינוך
			</p>
			<div>
				{!isLoading && camps && camps.length ? (
					<select
						className="px-4 py-2 rounded-full mb-2 text-black text-sm"
						onChange={(e) => {
							setRegisterData({
								...registerData,
								selectedCamp: camps.find((camp) => camp.id === parseInt(e.target.value)),
							});
						}}>
						<option value="0">בחר/י קייטנה</option>
						{camps
							.filter((camp) => {
								return camp.hidden_at === null;
							})
							.map((camp) => {
								return (
									<option key={camp.id} value={camp.id}>
										{camp.name}
									</option>
								);
							})}
					</select>
				) : (
					<div>בטעינה...</div>
				)}
			</div>
			{selectedCamp && (
				<div className="grid grid-cols-3 text-right mx-auto text-md max-w-[200px]">
					<b className="col-span-2">מחיר:</b>
					<span className="col-span-1">
						{selectedCamp.discount_price ? (
							<span>
								<del className="text-xs text-red-200">₪{selectedCamp.price}</del>
								&nbsp;&nbsp;
								<span className="font-bold">₪{selectedCamp.discount_price}</span>
							</span>
						) : (
							<span>₪{selectedCamp.price}</span>
						)}
					</span>
					{selectedCamp.discount_price && selectedCamp.discount_price_ends_at ? <span className="block col-span-3 mt-1 text-xs">המחיר בתוקף עד {discountPriceDate}</span> : null}
				</div>
			)}
			<div className="mt-4">
				<Button
					onClick={() => {
						setRegisterData({
							...registerData,
							step: 2,
						});
					}}
					disabled={selectedCamp == null}>
					המשך
				</Button>
			</div>
		</div>
	);
}
