import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../Button";
import { useRegistrationForm } from "../core/RegistrationFormProvider";
import { RegistrationFormIndicator } from "../RegistrationFormIndicator";

import { healthInformationValidationSchema as validationSchema } from "../../../utils/validationSchemas";
import { phonePrefixes } from "../../../utils/phonePrefixes";

export default function HealthInformationTab() {
	const { isLoading, registerData, setRegisterData } = useRegistrationForm();
	const { step } = registerData || {};

	const onSubmit = (values) => {
		/**
		 * The phone number is a combination of the prefix and the number.
		 */
		let contact_phone = "";

		if (values.contact_phone_prefix && values.contact_phone) {
			contact_phone = values.contact_phone_prefix + values.contact_phone;
		}

		setRegisterData((prevState) => ({
			...prevState,
			health_information: {
				...values,
				contact_phone,
			},
			step: prevState.step + 1,
		}));
	};

	const formik = useFormik({
		initialValues: {
			healthy: "",
			has_sensitivities: "",
			sensitivities: "",
			risky_sensitivities: "",
			// has_allergies: "",
			// allergies: "",
			can_swim: "",
			contact_name: "",
			contact_gov_id: "",
			contact_phone_prefix: "",
			contact_phone: "",
			terms_agree: false,
		},
		validationSchema,
		onSubmit,
	});

	useEffect(() => {
		if (!registerData) {
			return;
		}

		const currentValues = registerData?.health_information;

		let newValues = {};
		for (let key in formik.initialValues) {
			if (currentValues && currentValues[key] !== undefined && currentValues[key] !== null && currentValues[key] !== "") {
				newValues[key] = currentValues[key];
			} else {
				newValues[key] = formik.initialValues[key];
			}
		}

		/**
		 * The phone numbers should be split to prefix and number.
		 */
		if (newValues["contact_phone"] && newValues["contact_phone"].length === 10) {
			newValues["contact_phone_prefix"] = newValues["contact_phone"].substring(0, 3);
			newValues["contact_phone"] = newValues["contact_phone"].substring(3);
		}

		formik.setValues(newValues);
	}, [registerData]);

	let isMale = registerData && registerData.gender === "male";
	let healthStatement = (isMale ? "בני" : "בתי") + ", " + registerData.first_name + " " + registerData.last_name + ":";

	const { touched, errors, values, isSubmitting } = formik;

	return (
		<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
			<RegistrationFormIndicator />
			<form onSubmit={formik.handleSubmit}>
				<div className="grid md:grid-cols-2 md:gap-8 text-right mx-auto text-md w-full">
					<div className="md:col-span-2">
						<div className="text-right">
							<h3 className="text-2xl font-bold">הצהרת בריאות</h3>
							<p className="block mt-2">{healthStatement}</p>
						</div>
					</div>
					<div className="col-span-1">
						{/* Healthy */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="healthy">
										בריא לחלוטין
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select
										id="healthy"
										{...formik.getFieldProps("healthy")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.healthy && errors && errors.healthy ? "border-red-600" : "border-transparent"}`}
										onChange={(e) => {
											const { value } = e.target;

											if (value === "true") {
												formik.setFieldValue("healthy", true);
											} else if (value === "false") {
												formik.setFieldValue("healthy", false);
											} else {
												formik.handleChange(e);
											}
										}}>
										<option value="">בחר/י</option>
										<option value={false}>לא</option>
										<option value={true}>כן</option>
									</select>
									{touched && touched.healthy && errors && errors.healthy && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.healthy}</span>}
								</div>
							</div>
						</div>
						{/* Has sensitivities */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="has_sensitivities">
										רגישויות
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select
										id="has_sensitivities"
										{...formik.getFieldProps("has_sensitivities")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.has_sensitivities && errors && errors.has_sensitivities ? "border-red-600" : "border-transparent"}`}
										onChange={(e) => {
											const { value } = e.target;

											if (value === "true") {
												formik.setFieldValue("has_sensitivities", true);
											} else if (value === "false") {
												formik.setFieldValue("has_sensitivities", false);
											} else {
												formik.handleChange(e);
											}

											formik.setFieldValue("sensitivities", "");
										}}>
										<option value="">בחר/י</option>
										<option value={false}>לא</option>
										<option value={true}>כן</option>
									</select>
									{touched && touched.has_sensitivities && errors && errors.has_sensitivities && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.has_sensitivities}</span>}
								</div>
							</div>
						</div>
						{/* Sensitivities */}
						{values && values.has_sensitivities && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="sensitivities">
											פירוט רגישויות
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<input
											id="sensitivities"
											{...formik.getFieldProps("sensitivities")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.sensitivities && errors && errors.sensitivities ? "border-red-600" : "border-transparent"}`}
											type="text"
										/>
										{touched && touched.sensitivities && errors && errors.sensitivities && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.sensitivities}</span>}
									</div>
								</div>
							</div>
						)}
						{values && values.has_sensitivities && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="risky_sensitivities">
											האם הרגישות מסכנת חיים?
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<select
											id="risky_sensitivities"
											{...formik.getFieldProps("risky_sensitivities")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.risky_sensitivities && errors && errors.risky_sensitivities ? "border-red-600" : "border-transparent"}`}
											onChange={(e) => {
												const { value } = e.target;

												if (value === "true") {
													formik.setFieldValue("risky_sensitivities", true);
												} else if (value === "false") {
													formik.setFieldValue("risky_sensitivities", false);
												} else {
													formik.handleChange(e);
												}
											}}>
											<option value="">בחר/י</option>
											<option value={false}>לא</option>
											<option value={true}>כן</option>
										</select>
										{touched && touched.risky_sensitivities && errors && errors.risky_sensitivities && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.risky_sensitivities}</span>}
									</div>
								</div>
							</div>
						)}
						{/* Can swim */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="can_swim">
										יודע לשחות
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select
										id="can_swim"
										{...formik.getFieldProps("can_swim")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.can_swim && errors && errors.can_swim ? "border-red-600" : "border-transparent"}`}
										onChange={(e) => {
											const { value } = e.target;

											if (value === "true") {
												formik.setFieldValue("can_swim", true);
											} else if (value === "false") {
												formik.setFieldValue("can_swim", false);
											} else {
												formik.handleChange(e);
											}
										}}>
										<option value="">בחר/י</option>
										<option value={false}>לא</option>
										<option value={true}>כן</option>
									</select>
									{touched && touched.can_swim && errors && errors.can_swim && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.can_swim}</span>}
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-1">
						{/* Contact name */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="contact_name">
										שם ההורה
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="contact_name"
										{...formik.getFieldProps("contact_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.contact_name && errors && errors.contact_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.contact_name && errors && errors.contact_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.contact_name}</span>}
								</div>
							</div>
						</div>
						{/* Contact gov id */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="contact_gov_id">
										ת.ז ההורה
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="contact_gov_id"
										{...formik.getFieldProps("contact_gov_id")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.contact_gov_id && errors && errors.contact_gov_id ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.contact_gov_id && errors && errors.contact_gov_id && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.contact_gov_id}</span>}
								</div>
							</div>
						</div>
						{/* Contact phone */}
						<div className="mb-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="contact_phone_prefix">
										טלפון ההורה
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<div className="flex flex-row-reverse items-center gap-4">
										<div className="w-1/4">
											<select
												id="contact_phone_prefix"
												{...formik.getFieldProps("contact_phone_prefix")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.contact_phone_prefix && errors && errors.contact_phone_prefix ? "border-red-600" : "border-transparent"}`}>
												<option value="-">בחר/י</option>
												{phonePrefixes.map((prefix) => {
													return (
														<option key={prefix} value={prefix}>
															{prefix}
														</option>
													);
												})}
											</select>
										</div>
										<div className="w-3/4">
											<input
												id="contact_phone"
												{...formik.getFieldProps("contact_phone")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.contact_phone && errors && errors.contact_phone ? "border-red-600" : "border-transparent"}`}
												type="text"
											/>
										</div>
									</div>
									{touched && touched.contact_phone && errors && errors.contact_phone && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.contact_phone}</span>}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-0 text-right">
					<div className="mt-2">
						<div className="relative flex items-center pb-4">
							<label className="block w-full text-right" htmlFor="terms_agree">
								<input id="terms_agree" {...formik.getFieldProps("terms_agree")} disabled={isLoading || isSubmitting} type="checkbox" className="mr-2" checked={values?.terms_agree} />
								<span>
									אני מאשר/ת שקראתי את{" "}
									<a href="https://www.funkid-k.com/תקנון/" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>
										תנאי השימוש ותקנון הקייטנה
									</a>{" "}
									ואני מסכים/ה להם
								</span>
							</label>
							{errors && errors.terms_agree && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.terms_agree}</span>}
						</div>
					</div>
				</div>
				<div className="flex justify-between mt-8">
					<Button
						onClick={() => {
							setRegisterData((prevState) => ({
								...prevState,
								step: step - 1,
							}));
						}}
						disabled={isLoading || isSubmitting}>
						חזור
					</Button>
					<Button type="submit" disabled={isLoading || isSubmitting}>
						{isSubmitting ? "שומר..." : "המשך"}
					</Button>
				</div>
			</form>
		</div>
	);
}
