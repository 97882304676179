export const GetRegisterFields = async () => {
	const url = process.env.REACT_APP_API_URL + "registerFields";

	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	});

	const data = await response.json();

	if (!data.success) {
		throw new Error(data.message);
	}

	return data.data;
};

export const SubmitRegistration = async (payload, image) => {
	const url = process.env.REACT_APP_API_URL + "register";

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			...payload,
			camp_id: payload?.selectedCamp?.id,
			image,
		}),
	});

	const data = await response.json();

	if (!data.hasOwnProperty("success")) {
		throw new Error(data.message);
	} else if (!data.success) {
		return data;
	}

	return data;
};
