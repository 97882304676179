import React, { useEffect } from "react";
import { useFormik } from "formik";

import Button from "../../Button";
import { useRegistrationForm } from "../core/RegistrationFormProvider";
import { RegistrationFormIndicator } from "../RegistrationFormIndicator";
import { studentInformationValidationSchema as validationSchema } from "../../../utils/validationSchemas";

/**
 * The 'brithdate' field should not allow dates in the future, or dates that are closer to the current date than 6 years.
 */
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 6);

export default function StudentInformationTab() {
	const { isLoading, registerData, setRegisterData } = useRegistrationForm();
	const { step } = registerData || {};

	useEffect(() => {
		if (!registerData) {
			return;
		}

		Object.keys(validationSchema.fields).forEach((field) => {
			if (registerData.hasOwnProperty(field)) {
				formik.setFieldValue(field, registerData[field]);
			}
		});
	}, []);

	const onSubmit = (values) => {
		setRegisterData({
			...registerData,
			...values,
			step: step + 1,
		});
	};

	const formik = useFormik({
		validationSchema,
		initialValues: {
			first_name: "",
			last_name: "",
			grade: "",
			class_number: "",
			gov_id: "",
			birthdate: "",
			address: "",
			shirt_size: "",
			gender: "",
			hmo: "",
		},
		onSubmit,
	});

	const { errors, touched, isSubmitting } = formik;

	return (
		<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
			<RegistrationFormIndicator />
			<form onSubmit={formik.handleSubmit} aria-disabled={isLoading || isSubmitting}>
				<div className="grid md:grid-cols-2 md:gap-8 text-right mx-auto text-md w-full">
					<div className="col-span-1">
						{/* First name */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="first_name">
										שם פרטי
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="first_name"
										{...formik.getFieldProps("first_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.first_name && errors && errors.first_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.first_name && errors && errors.first_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.first_name}</span>}
								</div>
							</div>
						</div>
						{/* Last name */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="last_name">
										שם משפחה
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="last_name"
										{...formik.getFieldProps("last_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.last_name && errors && errors.last_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.last_name && errors && errors.last_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.last_name}</span>}
								</div>
							</div>
						</div>
						{/* Grade & Class number  */}
						<div className="mb-4 flex gap-4">
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-start gap-2">
									<div className="w-full md:w-2/5 flex items-center pt-3">
										<label className="font-semibold" htmlFor="grade">
											עולה לכיתה
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<select id="grade" {...formik.getFieldProps("grade")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.grade && errors && errors.grade ? "border-red-600" : "border-transparent"}`}>
											<option>-</option>
											<option value="1">א</option>
											<option value="2">ב</option>
											<option value="3">ג</option>
											<option value="4">ד</option>
											<option value="5">ה</option>
											<option value="6">ו</option>
											<option value="7">ז</option>
										</select>
										{touched && touched.grade && errors && errors.grade && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.grade}</span>}
									</div>
								</div>
							</div>
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-start gap-2">
									<div className="w-full md:w-2/5 flex items-center pt-3">
										<label className="font-semibold" htmlFor="class_number">
											מספר כיתה
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<select
											id="class_number"
											{...formik.getFieldProps("class_number")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.class_number && errors && errors.class_number ? "border-red-600" : "border-transparent"}`}>
											<option value={"-"}>-</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
											<option value="7">7</option>
										</select>
										{touched && touched.class_number && errors && errors.class_number && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.class_number}</span>}
									</div>
								</div>
							</div>
						</div>
						{/* Gov ID & Birthdate */}
						<div className="mb-4 flex gap-4">
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-2/5 pb-4">
										<label className="font-semibold" htmlFor="gov_id">
											ת.ז
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<input id="gov_id" {...formik.getFieldProps("gov_id")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.gov_id && errors && errors.gov_id ? "border-red-600" : "border-transparent"}`} type="text" />
										{touched && touched.gov_id && errors && errors.gov_id && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.gov_id}</span>}
									</div>
								</div>
							</div>
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-2/5 pb-4">
										<label className="font-semibold" htmlFor="birthdate">
											תאריך לידה
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<input
											id="birthdate"
											{...formik.getFieldProps("birthdate")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.birthdate && errors && errors.birthdate ? "border-red-600" : "border-transparent"}`}
											type="date"
											max={maxDate.toISOString().split("T")[0]}
										/>
										{touched && touched.birthdate && errors && errors.birthdate && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.birthdate}</span>}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-1">
						{/* Address */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="address">
										כתובת
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input id="address" {...formik.getFieldProps("address")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.address && errors && errors.address ? "border-red-600" : "border-transparent"}`} type="text" />
									{touched && touched.address && errors && errors.address && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.address}</span>}
								</div>
							</div>
						</div>
						{/* Shirt Size & Gender */}
						<div className="mb-4 flex gap-4">
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-2/5 pb-4">
										<label className="font-semibold" htmlFor="shirt_size">
											מידת חולצה
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<select id="shirt_size" {...formik.getFieldProps("shirt_size")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.shirt_size && errors && errors.shirt_size ? "border-red-600" : "border-transparent"}`}>
											<option value={"-"}>-</option>
											<option value="10">10</option>
											<option value="12">12</option>
											<option value="14">14</option>
											<option value="16">16</option>
											<option value="18">18</option>
											<option value="S">S</option>
											<option value="M">M</option>
											<option value="L">L</option>
											<option value="XL">XL</option>
										</select>
										{touched && touched.shirt_size && errors && errors.shirt_size && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.shirt_size}</span>}
									</div>
								</div>
							</div>
							<div className="w-full lg:w-1/2">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-2/5 pb-4">
										<label className="font-semibold" htmlFor="gender">
											מין
										</label>
									</div>
									<div className="relative w-full md:w-3/5 pb-4">
										<select id="gender" {...formik.getFieldProps("gender")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.gender && errors && errors.gender ? "border-red-600" : "border-transparent"}`}>
											<option value={"-"}>-</option>
											<option value="male">זכר</option>
											<option value="female">נקבה</option>
										</select>
										{touched && touched.gender && errors && errors.gender && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.gender}</span>}
									</div>
								</div>
							</div>
						</div>
						{/* HMO */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="hmo">
										קופת חולים וסניף
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input id="hmo" {...formik.getFieldProps("hmo")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.hmo && errors && errors.hmo ? "border-red-600" : "border-transparent"}`} type="text" />
									{touched && touched.hmo && errors && errors.hmo && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.hmo}</span>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="md:mt-4 text-right">
					<p>* השדה 'מספר כיתה' מייצג את מספר הכיתה בשכבה אליה הילד/ה רשום/ה. לדוגמה: אם הילד/ה בכיתה ג' 3, יש להזין בשדה את המספר 3.</p>
				</div>
				<div className="flex justify-between mt-8">
					{registerData && !registerData.hasOwnProperty("canChangeCamp") && (
						<Button
							onClick={() => {
								setRegisterData({
									...registerData,
									step: step - 1,
								});
							}}
							disabled={isLoading || isSubmitting}>
							חזור
						</Button>
					)}
					<Button type="submit" disabled={isLoading || isSubmitting} className="mr-auto">
						המשך
					</Button>
				</div>
			</form>
		</div>
	);
}
