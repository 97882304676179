import Layout from "../../../layouts/Layout";
import logo from "../../../storage/logo.png";

export default function OrderGatewayLoading() {
	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<div className="px-4">
						<header className="absolute right-0 left-0 top-[-67.5px] w-full">
							<img src={logo} alt="logo" className="mx-auto" />
						</header>
						<div className="py-12">
							<span>העמוד בטעינה</span>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
