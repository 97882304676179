import { useMutation } from "react-query";

export const GetOrder = async (orderUid) => {
	let url = process.env.REACT_APP_API_URL + "order/" + orderUid;

	/**
	 * The 'requestId' is a unique identifier for the request.
	 * It is loaded from the local storage, if it exists.
	 * If it's not found, a new one is generated (16 characters long).
	 */
	const requestId = sessionStorage.getItem("requestId") || Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);

	sessionStorage.setItem("requestId", requestId);

	url += "?request_id=" + requestId;

	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	});

	const data = await response.json();

	if (!data.success) {
		throw new Error(data.message);
	}

	return data.data;
};

export const CreateTransaction = async ({ orderUid, preference }) => {
	const url = process.env.REACT_APP_API_URL + "order/" + orderUid + "/create-transaction";

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			preference,
		}),
	});

	const data = await response.json();

	if (!data.success) {
		throw new Error(data.message);
	}

	return data.data;
};

export const useCreateTransaction = () => {
	return useMutation(CreateTransaction);
};

export const SetPaymentMethod = async ({ orderUid, payment_method }) => {
	let url = process.env.REACT_APP_API_URL + "order/" + orderUid + "/method";

	const response = await fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			payment_method,
		}),
	});

	const data = await response.json();

	if (!data.success) {
		throw new Error(data.message);
	}

	return data.data;
};

export const useSetPaymentMethod = () => {
	return useMutation(SetPaymentMethod);
};
