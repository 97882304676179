import { useState } from "react";
import Layout from "../../../layouts/Layout";
import logo from "../../../storage/logo.png";
import { useOrderGateway } from "../core/OrderGatewayProvider";
import { useSetPaymentMethod } from "../../../services/OrderService";

export default function OrderGatewayPayment({ allowMultipleMethods = false }) {
	const {
		order,
		order: { student, transactions },
	} = useOrderGateway();

	const [paymentMethod, setPaymentMethod] = useState(order?.method || "creditcard");

	const setPaymentMethodMutation = useSetPaymentMethod();

	const transaction = transactions.find((transaction) => transaction.is_paid === false);

	if (!transaction) {
		return null;
	}

	const { page_request_uid, type } = transaction;

	const savePaymentMethod = () => {
		setPaymentMethodMutation.mutate(
			{ orderUid: order.uid, payment_method: paymentMethod },
			{
				onSuccess: () => {
					alert("אמצעי התשלום נשמר בהצלחה.");
				},
			}
		);
	};

	const { isLoading } = setPaymentMethodMutation;

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px:4 md:px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<div className="px-4">
						<header className="absolute right-0 left-0 top-[-67.5px] w-full">
							<img src={logo} alt="logo" className="mx-auto" />
						</header>
						<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
					</div>
					{type === "advance-payment" && (
						<>
							<p className="text-lg font-light mb-4">
								שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
								<br />
								אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת "אקשן על גלגלים".
							</p>
							<p>
								לידיעתכם, <strong className="font-bold">שמירת מקום ילדכם בקייטנה מותנה בתשלום דמי רישום.</strong>
							</p>
						</>
					)}
					{type === "full-payment" && (
						<p className="text-md font-light mb-4">
							שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
							<br />
							<strong className="font-bold">תשלום דמי הרישום הושלם בהצלחה.</strong>
							<br />
							את יתרת התשלום יש לשלם, לאחר בחירת אמצעי התשלום, תוך 14 ימים קלנדריים מיום ביצוע ההרשמה על מנת להשלים את הרשמת ילדכם ולהבטיח את המקום בקייטנת אקשן על גלגלים.
							{/* אנחנו שמחים על החלטתכם להשלים את התשלום עבור ההרשמה של <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת אקשן על גלגלים. */}
						</p>
					)}
					{allowMultipleMethods && (
						<div className="w-full sm:w-3/4 mx-auto text-right">
							<span className="block mb-2 font-bold">בחר/י אמצעי תשלום</span>
							<div className="flex items-center">
								<select className="ml-2 bg-gray-200 text-gray-800 font-semibold py-4 px-6 w-full rounded-2xl" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} disabled={isLoading}>
									<option value="creditcard">כרטיס אשראי</option>
									<option value="cash">מזומן</option>
									<option value="check">המחאה</option>
								</select>
							</div>
						</div>
					)}
					{(!allowMultipleMethods || paymentMethod === "cc" || paymentMethod === "creditcard") && paymentMethod !== "cash" && paymentMethod !== "check" && (
						<iframe title="AAG Payment" src={`https://payments.payplus.co.il/${page_request_uid}`} className="tw-block tw-w-full tw-h-auto aspect-[10/17] md:aspect-[16/12]" width="100%"></iframe>
					)}

					{paymentMethod === "cash" && (
						<div className="w-full sm:w-3/4 mx-auto py-4 text-right">
							<div className="mb-4">
								<p>את היתרה לתשלום יש להעביר ביום הראשון לפעילות, לרכז האחראי על ילדכם, במעטפה סגורה עליה מצויין באופן ברור: שם הילד, נקודת האיסוף ומספר הנרשם</p>
							</div>
							<button className="block w-full py-4 px-6 bg-black text-white rounded-2xl" onClick={(e) => savePaymentMethod()} disabled={isLoading}>
								שמירת אמצעי תשלום
							</button>
						</div>
					)}

					{paymentMethod === "check" && (
						<div className="w-full sm:w-3/4 mx-auto py-4 text-right">
							<div className="mb-4">
								<p>
									את היתרה לתשלום ניתן לשלם בהמחאה אחת או לחלק לשלוש המחאות מקסימום.
									<br />
									את ההמחאות יש לרשום לפקודת "טי. אייצ' השקעות בע"מ".
									<br />
									מועד הפרעון של ההמחאות יחול לא יאוחר מיום תחילת הקייטנה וההמחאות הבאות יהיו בחודש ובחודשיים העוקבים, לא יאוחר מהעשירי לחודש.
									<br />
									את ההמחאות יש להעביר ביום הראשון לפעילות, לרכז האחראי על ילדכם, במעטפה סגורה עליה מצויין באופן ברור: שם הילד, נקודת האיסוף ומספר הנרשם.
								</p>
							</div>
							<button className="block w-full py-4 px-6 bg-black text-white rounded-2xl" onClick={(e) => savePaymentMethod(paymentMethod)} disabled={isLoading}>
								שמירת אמצעי תשלום
							</button>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
}
