import { OrderGatewayProvider, useOrderGateway } from "./core/OrderGatewayProvider";
import OrderGatewayLoading from "./components/OrderGatewayLoading";
import WorkersCommitteePaymentPage from "./components/WorkersCommitteePaymentPage";
import OrderGatewayChoosePayment from "./components/OrderGatewayChoosePayment";
import OrderGatewaySuccess from "./components/OrderGatewaySuccess";
import OrderGatewayPayment from "./components/OrderGatewayPayment";
// import Layout from "../../layouts/Layout";
// import logo from "../../storage/logo.png";

export default function OrderGateway() {
	return (
		<OrderGatewayProvider>
			<OrderGatewayContent />
		</OrderGatewayProvider>
	);
}

const OrderGatewayContent = () => {
	const { isLoading, isError, order } = useOrderGateway();

	console.log("order:", order);

	/**
	 * We have several cases of orders.
	 * 1. The order has no transactions. In this case, we have 2 scenarios:
	 * 1.1 The order is a regular order, so we need to allow the customer to choose between an advance payment and a full payment.
	 * 1.2 The order is a workers committee order, so we only allow the customer to pay the advance payment.
	 * 2. The order has transactions. In this case, we need to identify which transaction has been completed and redirect the customer to the appropriate page.
	 */

	if (isLoading || !order) {
		return <OrderGatewayLoading />;
	}

	const { method, transactions, status } = order;

	if (status === "Paid") {
		return <OrderGatewaySuccess type={method === "workers-committee" ? method : "normal"} />;
	}

	if (method === "workers-committee") {
		return <WorkersCommitteePaymentPage />;
	}

	if (!transactions || transactions.length === 0) {
		return <OrderGatewayChoosePayment />;
	}

	/**
	 * If the order has transactions, we need to identify which transaction has been completed.
	 */
	const advanceTransaction = transactions.find((transaction) => transaction.type === "advance-payment");
	const fullTransaction = transactions.find((transaction) => transaction.type === "full-payment");

	/**
	 * No transaction has been completed yet, so we need to allow the customer to choose a payment option.
	 */
	if (!advanceTransaction && !fullTransaction) {
		return <OrderGatewayChoosePayment />;
	} else if (advanceTransaction && fullTransaction && advanceTransaction.is_paid && fullTransaction.is_paid) {
		return <OrderGatewaySuccess type="normal" />;
	} else if ((advanceTransaction && !advanceTransaction.is_paid) || (fullTransaction && !fullTransaction.is_paid)) {
		return <OrderGatewayPayment allowMultipleMethods={advanceTransaction && advanceTransaction.is_paid} />;
	}

	return (
		<div>
			<h1>Order Gateway</h1>
		</div>
	);
};
