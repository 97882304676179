import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Layout from "../../../layouts/Layout";

import Canvas from "../../../components/Canvas";
import Button from "../../../components/Button";

import logo from "../../../storage/logo.png";
import { useRegistrationForm } from "../core/RegistrationFormProvider";
import { SubmitRegistration } from "../../../services/RegisterService";

const couponValidationSchema = Yup.object().shape({
	coupon: Yup.string().required("שדה חובה").min(3, "קוד קופון חייב להכיל לפחות 3 תווים"),
});

export default function ConfirmationTab() {
	const [searchParams, setSearchParams] = useSearchParams();

	const { isLoading, registerData, setRegisterData } = useRegistrationForm();
	const [pageEnabled, setPageEnabled] = useState(false);
	const [errors, setErrors] = useState([]);

	const navigate = useNavigate();

	const [canvasData, setCanvasData] = useState(null);
	const [couponPopup, setCouponPopup] = useState(false);

	const couponParam = searchParams.get("coupon");

	const renderPriceLabel = () => {
		if (!registerData) {
			return null;
		}

		const { selectedCamp, coupon, contact_information } = registerData;

		let { price, discount_price } = selectedCamp || {};
		let actual_price = price;

		if (!price) {
			return null;
		}

		if (discount_price && discount_price > 0) {
			actual_price = discount_price;
		}

		if (coupon) {
			actual_price -= coupon.discount;
		}

		if (actual_price < 0) {
			actual_price = 0;
		}

		if (contact_information && contact_information.workers_committee) {
			return <span>50₪</span>;
		}

		if (price === actual_price) {
			return <span>{price}₪</span>;
		}

		return (
			<span>
				<span className="block text-gray-500 line-through">{price}₪</span>
				<span className="block ml-2">{actual_price}₪</span>
			</span>
		);
	};

	const handleRegistrationSubmission = async () => {
		setPageEnabled(false);
		setErrors([]);

		try {
			const submitData = { ...registerData };

			if (submitData.contact_information) {
				["mother_phone", "father_phone", "emergency_phone"].forEach((key) => {
					if (submitData.contact_information[key] && typeof submitData.contact_information[key] === "string" && submitData.contact_information[key] === "-") {
						delete submitData.contact_information[key];
					}
				});
			}

			const response = await SubmitRegistration(submitData, canvasData);

			const { success, data } = response;

			if (!success) {
				let { message } = response;

				if (message && message.length > 0) {
					if (message === "Student already exists.") {
						message = "התלמיד כבר רשום לקייטנה. אנא בדקו את תיבת המייל שלכם, או צרו קשר עם הנהלת הקייטנה.";
					}

					setErrors([message]);
				}

				return;
			}

			const { order } = data;

			if (!order) {
				console.error("Error submitting registration [no order]:", response);
				return;
			}

			const { uid } = order;

			if (!uid) {
				console.error("Error submitting registration [no uid]:", response);
				return;
			}

			// Reset the registration data
			setRegisterData(null);

			// Redirect to the order page
			return navigate(`/order/${uid}`);
		} catch (error) {
			console.error("error:", error);
		} finally {
			setPageEnabled(true);
		}
	};

	const validateCoupon = async (code) => {
		let url = `${process.env.REACT_APP_API_URL}coupon/validate`;
		let response;

		try {
			response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					code,
					email: registerData?.contact_information?.email,
				}),
			});
		} catch (error) {
			console.error("Error validating coupon:", error);

			return false;
		}

		let { success, data, message } = await response.json();

		if (!success) {
			if (message === "Coupon not found.") {
				message = "קוד הקופון אינו תקין.";
			} else if (message == "Coupon has expired.") {
				message = "קוד הקופון פג תוקפו.";
			} else if (message == "Coupon has reached its limit.") {
				message = "קוד הקופון הגיע למגבלת השימוש.";
			} else if (message == "Too Many Attempts.") {
				message = "ניסיונות רבים מדי. נסה שוב מאוחר יותר.";
			}

			couponForm.setErrors({
				coupon: message,
			});

			couponForm.setSubmitting(false);
			return;
		}

		setRegisterData((prevState) => ({
			...prevState,
			coupon: data,
		}));

		setCouponPopup(false);
	};

	const couponForm = useFormik({
		initialValues: {
			coupon: "",
		},
		validationSchema: couponValidationSchema,
		onSubmit: (values) => {
			couponForm.setSubmitting(false);

			validateCoupon(values.coupon);
		},
	});

	useEffect(() => {
		if (!registerData) {
			return;
		}

		setPageEnabled(true);
	}, [registerData]);

	useEffect(() => {
		if (!couponParam) {
			return;
		}

		validateCoupon(couponParam).then(() => {
			setSearchParams({});
		});
	}, [couponParam]);

	// {"selectedCamp":{"id":5,"name":"קייטנה לבדיקה","registrations_opened":true,"price":1250,"discount_price":null,"discount_price_ends_at":null,"created_at":"2024-02-19T09:41:52.000000Z","updated_at":"2024-02-21T16:00:05.000000Z","deleted_at":null},"step":6,"first_name":"תמיר","last_name":"נחום","grade":"2","class_number":"2","gov_id":"316589613","birthdate":"2017-10-24","address":"אנגל 78","shirt_size":"12","gender":"male","hmo":"מכבי","contact_information":{"mother_name":"תמיר","father_name":"תום","email":"tamir@thm.co.il","mother_phone_prefix":"054","mother_phone":"0543000745","father_phone_prefix":"054","father_phone":"0543000745","emergency_name":"תמיר","emergency_closeness":"אח","emergency_phone_prefix":"054","emergency_phone":"0543000745","workers_committee":true,"workers_committee_id":"2","workers-committee":false},"pickup_information":{"pickup_city":"10","pickup_school":"47","pickup_type":"יאסף בליווי אדם מבוגר מטעם המשפחה","pickup_contact_name":"תמיר","pickup_contact_phone":"0543000745","pickup_contact_closeness":"אח","pickup_agree":true,"comment":"הערה לבדיקה"},"health_information":{"healthy":true,"has_sensitivities":true,"sensitivities":"רגיש לערסים","risky_sensitivities":true,"can_swim":true,"contact_name":"תמיר","contact_gov_id":"316578909","contact_phone_prefix":"054","contact_phone":"0543000745","terms_agree":true}}

	const couponsEnabled = registerData && registerData.contact_information && !registerData.contact_information.workers_committee;

	return (
		<>
			{couponPopup && (
				<div className="fixed inset-0 w-full h-full grid place-items-center z-20 bg-opacity-70 bg-black">
					<div className="relative max-w-md w-full mx-auto my-auto py-4 px-6 bg-white rounded-xl shadow-lg">
						<button onClick={() => setCouponPopup(false)} className="absolute top-2 left-4 text-black">
							&times;
						</button>
						<h2 className="text-2xl font-semibold mb-4">מימוש קופון</h2>
						<form onSubmit={couponForm.handleSubmit}>
							<div className="mb-4">
								<label htmlFor="coupon" className="block text-sm mb-1">
									קוד קופון
								</label>
								<input type="text" name="coupon" className="px-4 py-3 rounded-2xl w-full text-black text-sm border" onChange={couponForm.handleChange} onBlur={couponForm.handleBlur} value={couponForm.values.coupon} />
								{couponForm.touched.coupon && couponForm.errors.coupon && <div className="text-red-500 text-xs">{couponForm.errors.coupon}</div>}
							</div>
							<div className="mb-4">
								<button type="submit" className="rounded-3xl bg-blue-500 text-white py-3 px-6" disabled={couponForm.isSubmitting}>
									מימוש
								</button>
								{couponForm.isSubmitting && <div className="mt-2 text-sm text-gray-500">הקופון בבדיקה...</div>}
							</div>
						</form>
					</div>
				</div>
			)}
			<Layout>
				<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl">
					<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
						<header className="absolute top-[-67.5px] w-full">
							<img src={logo} alt="logo" className="mx-auto" />
						</header>
						<div className="px-4">
							<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
							<div>
								<p className="text-lg font-light mb-4">
									שלום {registerData?.contact_information?.mother_name} ו{registerData?.contact_information?.father_name},
									<br />
									אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{registerData?.first_name + " " + registerData?.last_name}</strong> לקייטנת אקשן על גלגלים.
								</p>
								<div className="mb-6">
									<table className="w-full table-auto">
										<thead>
											<tr>
												<th className="border border-gray-300 px-4 py-2 break-all">שם הילד/ה</th>
												<th className="border border-gray-300 px-4 py-2 break-all">קייטנה</th>
												<th className="border border-gray-300 px-4 py-2 break-all">{registerData?.contact_information && registerData?.contact_information.workers_committee ? <span>דמי רישום</span> : <span>עלות הקייטנה</span>}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="border border-gray-300 px-4 py-2">{registerData?.first_name + " " + registerData?.last_name}</td>
												<td className="border border-gray-300 px-4 py-2">{registerData?.selectedCamp?.name}</td>
												<td className="border border-gray-300 px-4 py-2">{renderPriceLabel()}</td>
											</tr>
										</tbody>
									</table>
								</div>
								{couponsEnabled && (
									<>
										{registerData.coupon ? (
											<div className="mb-6">
												<p className="text-green-500">
													<strong>ברכות! קיבלת {registerData.coupon.discount}₪ הנחה משום שהשתמשת בקופון.</strong>
												</p>
											</div>
										) : (
											<div className="mb-6">
												<p>
													<strong>יש לך קוד קופון?</strong>
													&nbsp;
													<button
														className="text-blue-500 underline"
														onClick={() => {
															setCouponPopup(true);
														}}>
														לחץ/י כאן
													</button>
												</p>
											</div>
										)}
									</>
								)}
								<p className="block">
									על מנת לעבור לשלב הבא בהרשמה, עליכם לחתום כי הנכם מאשרים את רישום <strong className="font-bold">{registerData?.first_name + " " + registerData?.last_name}</strong> בתיבת החתימה הדיגיטלית שמופיעה מטה:
								</p>
								<div className="mt-2">
									<Canvas setCanvasData={setCanvasData} />
								</div>
							</div>
						</div>
						{errors &&
							errors.length > 0 &&
							errors.map((error, index) => {
								return (
									<div className="mt-4 text-red-500 text-sm" key={index}>
										<span>{error}</span>
									</div>
								);
							})}

						<div className="flex justify-between mt-8 px-4">
							<Button
								onClick={() => {
									setRegisterData((prevState) => ({
										...prevState,
										step: prevState.step - 1,
									}));
								}}
								disabled={isLoading || !pageEnabled}>
								חזור
							</Button>

							<Button onClick={() => handleRegistrationSubmission()} disabled={isLoading || !pageEnabled}>
								שמור והמשך
							</Button>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}
