import Layout from "../../../layouts/Layout";
import { useCreateTransaction } from "../../../services/OrderService";
import logo from "../../../storage/logo.png";
import { useOrderGateway } from "../core/OrderGatewayProvider";

export default function OrderGatewayChoosePayment() {
	const {
		order,
		order: { student, transactions },
		refetch,
	} = useOrderGateway();

	const transactionMutation = useCreateTransaction();

	/**
	 * This component should be rendered only if the order has no transactions.
	 */
	if (transactions && transactions.length > 0) {
		return null;
	}

	const selectPaymentPreference = (orderUid, preference) => {
		transactionMutation.mutate(
			{
				orderUid,
				preference,
			},
			{
				onSuccess: () => {
					refetch();
				},
			}
		);
	};

	return (
		<Layout>
			<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl">
				<div className="max-w-full w-full mx-auto my-auto pt-20 pb-5 text-center rounded-xl bg-white text-black relative">
					<header className="absolute top-[-67.5px] w-full">
						<img src={logo} alt="logo" className="mx-auto" />
					</header>
					<div className="px-4">
						<strong className="text-2xl font-semibold mb-4 block">הרשמה לקייטנה</strong>
						<div>
							<p className="text-lg font-light mb-4">
								שלום {student?.contact_information?.mother_name} ו{student?.contact_information?.father_name},
								<br />
								אנחנו שמחים על החלטתכם לרשום את <strong className="font-bold">{student.first_name + " " + student.last_name}</strong> לקייטנת "אקשן על גלגלים".
							</p>
							<div>
								<p>
									{/* באפשרותך לבחור האם לשלם את מלוא הסכום עבור הרישום לקייטנה, או לשלם את דמי המקדמה בסך <strong className="font-bold">250₪</strong> בלבד לצורך רישום והבטחת מיקום. */}
									{/* כעת באפשרותך לבחור האם לשלם את מלוא הסכום או לשלם את תשלום המקדמה על סך 250₪ ובכך להבטיח את מקום ילדך ב"אקשן על גלגלים".
									<br />
									במקרה זה, את יתרת התשלום יש להסדיר תוך 14 ימים (קלנדריים). לתשומת ליבכם - הרשמה אשר לא יוסדר עבורה התשלום המלא תוך פרק הזמן המצויין לעיל תבוטל באופן אוטומטי, מקום ילדכם לא ישמר ודמי הרישום יוחזרו בהתאם למפורט בתקנון עליו אישרתם. */}
									כעת באפשרותכם לבחור האם לשלם את מלוא הסכום או לשלם דמי רישום על סך 250₪ ובכך להבטיח את מקום ילדכם בקייטנת "אקשן על גלגלים". במקרה זה, יש להסדיר תוך 14 ימים (קלנדריים) את יתרת התשלום.
									<br />
									<strong>לתשומת ליבכם</strong> הרשמה אשר לא יוסדר עבורה התשלום המלא תוך פרק הזמן המצויין לעיל (14 ימים קלדנריים), תבוטל באופן אוטומטי ומקום ילדכם לא ישמר. במקרה כזה, עסקת ההתקשרות עם קייטנת "אקשן על גלגלים", אשר התחילה עם שליחת טופס ההרשמה, תבוטל ודמי הרישום יוחזרו בניכוי 5% מכלל
									העסקה או 100 ש"ח (הנמוך מביניהם) כמפורט בתנאי הביטול המופיעים בתקנון הקייטנה אותו אישרתם בעת ביצוע ההרשמה.
								</p>

								<div className="grid lg:grid-cols-2 gap-8 mt-8">
									<button className="block w-full bg-gray-200 hover:bg-gray-300 text-black rounded-3xl shadow-lg font-bold text-xl" style={{ aspectRatio: "3/1" }} onClick={() => selectPaymentPreference(order.uid, "advance")}>
										תשלום דמי רישום
										<br />
										<small>(250₪)</small>
									</button>
									<button className="block w-full bg-gray-200 hover:bg-gray-300 text-black rounded-3xl shadow-lg font-bold text-xl" style={{ aspectRatio: "3/1" }} onClick={() => selectPaymentPreference(order.uid, "full")}>
										תשלום מלא
										<br />
										<small>({order.total_unpaid}₪)</small>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
