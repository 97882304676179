import { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { GetRegisterFields } from "../../../services/RegisterService";

const RegistrationFormContext = createContext();

export const RegistrationFormProvider = ({ children }) => {
	const [registerData, setRegisterData] = useState(null);

	/**
	 * The 'registerData' should be loaded from the local storage, if it exists.
	 * Based on that we can determine the current step.
	 */
	useEffect(() => {
		const data = sessionStorage.getItem("registerData");

		if (data) {
			setRegisterData(JSON.parse(data));
		}
	}, []);

	/**
	 * The 'registerData' should be saved to the local storage, if it changes.
	 */
	useEffect(() => {
		if (registerData) {
			sessionStorage.setItem("registerData", JSON.stringify(registerData));
		} else {
			sessionStorage.removeItem("registerData");
		}
	}, [registerData]);

	const {
		isLoading: isLoadingRegisterFields,
		data: registerFields,
		isError: isErrorRegisterFields,
	} = useQuery("registerFields", GetRegisterFields, {
		enabled: true,
		refetchOnWindowFocus: false,
	});

	const { camps, cities, workers_committees } = registerFields || {};

	const isLoading = isLoadingRegisterFields;

	return <RegistrationFormContext.Provider value={{ isLoading, camps, cities, workers_committees, registerData, setRegisterData }}>{children}</RegistrationFormContext.Provider>;
};

export const useRegistrationForm = () => useContext(RegistrationFormContext);
