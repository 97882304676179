import Layout from "../../layouts/Layout";
import { RegistrationFormProvider, useRegistrationForm } from "./core/RegistrationFormProvider";
import ConfirmationTab from "./tabs/ConfirmationTab";
import ContactInformationTab from "./tabs/ContactInformationTab";
import HealthInformationTab from "./tabs/HealthInformationTab";
import PickupInformationTab from "./tabs/PickupInformationTab";
import SelectCampTab from "./tabs/SelectCampTab";
import StudentInformationTab from "./tabs/StudentInformationTab";

export default function RegistrationForm() {
	return (
		<RegistrationFormProvider>
			<RegistrationFormContent />
		</RegistrationFormProvider>
	);
}

const RegistrationFormContent = () => {
	const { registerData, setRegisterData } = useRegistrationForm();
	const { step } = registerData || {};

	/*
	{"selectedCamp":{"id":5,"name":"קייטנה לבדיקה","registrations_opened":true,"price":1250,"discount_price":null,"discount_price_ends_at":null,"reminders_at":"2024-04-17","created_at":"2024-02-19T09:41:52.000000Z","updated_at":"2024-04-02T15:33:45.000000Z","deleted_at":null}
	,"step":6,"first_name":"תמיר","last_name":"נחום","grade":"2","class_number":"2","gov_id":"212345676","birthdate":"2015-10-24","address":"אנגל 78","shirt_size":"14","gender":"female","hmo":"מכבי כפר סבא",
	"contact_information":{"mother_name":"תמיר","father_name":"תום","email":"tamir@thm.co.il","mother_phone_prefix":"054","mother_phone":"0543000745","father_phone_prefix":"054","father_phone":"0543000745","emergency_name":"תמיר","emergency_closeness":"אח","emergency_phone_prefix":"054","emergency_phone":"0543000745","workers_committee":false,"workers_committee_id":null,"workers-committee":false},
	"pickup_information":{"pickup_city":"10","pickup_school":"47","pickup_type":"ילדי חוזר הביתה בכוחות עצמו","pickup_contact_name":"","pickup_contact_phone":"","pickup_contact_closeness":"","pickup_agree":true,"comment":""},
	"health_information":{"healthy":true,"has_sensitivities":false,"sensitivities":"","risky_sensitivities":"","can_swim":true,"contact_name":"תמיר","contact_gov_id":"123123422","contact_phone_prefix":"054","contact_phone":"0543000745","terms_agree":true},
	"coupon":{"code":"1231235","discount":120,"expiring_at":"2024-04-17T21:00:00.000000Z"}}
	*/

	return (
		<Layout>
			{(!step || step === 1) && <SelectCampTab />}
			{step === 2 && <StudentInformationTab />}
			{step === 3 && <ContactInformationTab />}
			{step === 4 && <PickupInformationTab />}
			{step === 5 && <HealthInformationTab />}
			{step === 6 && <ConfirmationTab />}
		</Layout>
	);
};
