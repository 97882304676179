import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../Button";
import { useRegistrationForm } from "../core/RegistrationFormProvider";
import { RegistrationFormIndicator } from "../RegistrationFormIndicator";

import { contactInformationValidationSchema as validationSchema } from "../../../utils/validationSchemas";
import { phonePrefixes } from "../../../utils/phonePrefixes";

export default function ContactInformationTab() {
	const { isLoading, workers_committees, registerData, setRegisterData } = useRegistrationForm();
	const { step } = registerData || {};

	const onSubmit = (values) => {
		console.log("Values:", values);

		/**
		 * The 'workers-committee' value should be a boolean.
		 * So we need to convert it from a string to a boolean.
		 */
		values["workers-committee"] = values["workers-committee"] === "true";

		/**
		 * The phone numbers should be a concatenation of the prefix and the number.
		 */
		values["mother_phone"] = values["mother_phone_prefix"] + values["mother_phone"];
		values["father_phone"] = values["father_phone_prefix"] + values["father_phone"];
		values["emergency_phone"] = values["emergency_phone_prefix"] + values["emergency_phone"];

		setRegisterData({
			...registerData,
			contact_information: {
				...values,
			},
			step: step + 1,
		});
	};

	const formik = useFormik({
		initialValues: {
			mother_name: "",
			father_name: "",
			email: "",
			mother_phone_prefix: "",
			mother_phone: "",
			father_phone_prefix: "",
			father_phone: "",
			emergency_name: "",
			emergency_closeness: "",
			emergency_phone_prefix: "",
			emergency_phone: "",
			workers_committee: false,
			workers_committee_id: null,
		},
		validationSchema: validationSchema,
		onSubmit,
	});

	useEffect(() => {
		if (!registerData) {
			return;
		}

		const currentValues = registerData?.contact_information || {};

		/**
		 * Set the form values from the saved data, but only fields that exist in the initial values.
		 */
		let newValues = {};
		for (let key in formik.initialValues) {
			if (currentValues.hasOwnProperty(key) && currentValues[key] !== undefined && currentValues[key] !== null && currentValues[key] !== "") {
				newValues[key] = currentValues[key];
			} else {
				newValues[key] = formik.initialValues[key];
			}
		}

		/**
		 * The phone numbers should be split to prefix and number.
		 */
		if (newValues["mother_phone"] && newValues["mother_phone"].length === 10) {
			newValues["mother_phone_prefix"] = newValues["mother_phone"].substring(0, 3);
			newValues["mother_phone"] = newValues["mother_phone"].substring(3);
		}

		if (newValues["father_phone"] && newValues["father_phone"].length === 10) {
			newValues["father_phone_prefix"] = newValues["father_phone"].substring(0, 3);
			newValues["father_phone"] = newValues["father_phone"].substring(3);
		}

		if (newValues["emergency_phone"] && newValues["emergency_phone"].length === 10) {
			newValues["emergency_phone_prefix"] = newValues["emergency_phone"].substring(0, 3);
			newValues["emergency_phone"] = newValues["emergency_phone"].substring(3);
		}

		const phoneFields = ["mother_phone", "father_phone", "emergency_phone"];

		phoneFields.forEach((field) => {
			if (newValues.hasOwnProperty(field) && newValues[field] === "-") {
				newValues[field] = "";
			}
		});

		formik.setValues(newValues);
	}, [registerData]);

	/**
	 * Add a placeholder item to the beginning of the array, if not exists.
	 */
	if (workers_committees && workers_committees[0]?.id !== null && workers_committees[0]?.id !== "-") {
		workers_committees.unshift({
			id: "-",
			name: "-",
		});
	}

	const { touched, errors, values, isSubmitting } = formik;

	return (
		<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
			<RegistrationFormIndicator />
			<form onSubmit={formik.handleSubmit}>
				<div className="grid md:grid-cols-2 md:gap-8 text-right mx-auto text-md w-full">
					<div className="col-span-1">
						{/* Mother name  */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="mother_name">
										שם הורה 1
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="mother_name"
										{...formik.getFieldProps("mother_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.mother_name && errors && errors.mother_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.mother_name && errors && errors.mother_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.mother_name}</span>}
								</div>
							</div>
						</div>
						{/* Father name  */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="father_name">
										שם הורה 2
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="father_name"
										{...formik.getFieldProps("father_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.father_name && errors && errors.father_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.father_name && errors && errors.father_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.father_name}</span>}
								</div>
							</div>
						</div>
						{/* Email */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="email">
										אימייל
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input id="email" {...formik.getFieldProps("email")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.email && errors && errors.email ? "border-red-600" : "border-transparent"}`} type="email" />
									{touched && touched.email && errors && errors.email && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.email}</span>}
								</div>
							</div>
						</div>
						{/* Mother phone */}
						<div className="mb-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="mother_phone_prefix">
										טלפון של הורה 1
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<div className="flex flex-row-reverse items-center gap-4">
										<div className="w-1/4">
											<select
												id="mother_phone_prefix"
												{...formik.getFieldProps("mother_phone_prefix")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.mother_phone_prefix && errors && errors.mother_phone_prefix ? "border-red-600" : "border-transparent"}`}>
												<option value="-">בחר/י</option>
												{phonePrefixes.map((prefix) => {
													return (
														<option key={prefix} value={prefix}>
															{prefix}
														</option>
													);
												})}
											</select>
										</div>
										<div className="w-3/4">
											<input
												id="mother_phone"
												{...formik.getFieldProps("mother_phone")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.mother_phone && errors && errors.mother_phone ? "border-red-600" : "border-transparent"}`}
												type="text"
											/>
										</div>
									</div>
									{touched && touched.mother_phone && errors && errors.mother_phone && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.mother_phone}</span>}
								</div>
							</div>
							<span className="block text-black">הודעות ועדכונים ישלחו למספר הטלפון של הורה 1 בלבד.</span>
						</div>
						{/* Father phone */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="father_phone_prefix">
										טלפון של הורה 2
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<div className="flex flex-row-reverse items-center gap-4">
										<div className="w-1/4">
											<select
												id="father_phone_prefix"
												{...formik.getFieldProps("father_phone_prefix")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.father_phone_prefix && errors && errors.father_phone_prefix ? "border-red-600" : "border-transparent"}`}>
												<option value="-">בחר/י</option>
												{phonePrefixes.map((prefix) => {
													return (
														<option key={prefix} value={prefix}>
															{prefix}
														</option>
													);
												})}
											</select>
										</div>
										<div className="w-3/4">
											<input
												id="father_phone"
												{...formik.getFieldProps("father_phone")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.father_phone && errors && errors.father_phone ? "border-red-600" : "border-transparent"}`}
												type="text"
											/>
										</div>
									</div>
									{touched && touched.father_phone && errors && errors.father_phone && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.father_phone}</span>}
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-1">
						{/* Emergency name  */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="emergency_name">
										שם למקרה חירום
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="emergency_name"
										{...formik.getFieldProps("emergency_name")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.emergency_name && errors && errors.emergency_name ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.emergency_name && errors && errors.emergency_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.emergency_name}</span>}
								</div>
							</div>
						</div>
						{/* Emergency closeness */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="emergency_closeness">
										קירבה למקרה חירום
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<input
										id="emergency_closeness"
										{...formik.getFieldProps("emergency_closeness")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.emergency_closeness && errors && errors.emergency_closeness ? "border-red-600" : "border-transparent"}`}
										type="text"
									/>
									{touched && touched.emergency_closeness && errors && errors.emergency_closeness && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.emergency_closeness}</span>}
								</div>
							</div>
						</div>
						{/* Emergency phone */}
						<div className="mb-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="emergency_phone_prefix">
										טלפון למקרה חירום
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<div className="flex flex-row-reverse items-center gap-4">
										<div className="w-1/4">
											<select
												id="emergency_phone_prefix"
												{...formik.getFieldProps("emergency_phone_prefix")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.emergency_phone_prefix && errors && errors.emergency_phone_prefix ? "border-red-600" : "border-transparent"}`}>
												<option value="-">בחר/י</option>
												{phonePrefixes.map((prefix) => {
													return (
														<option key={prefix} value={prefix}>
															{prefix}
														</option>
													);
												})}
											</select>
										</div>
										<div className="w-3/4">
											<input
												id="emergency_phone"
												{...formik.getFieldProps("emergency_phone")}
												disabled={isLoading || isSubmitting}
												className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.emergency_phone && errors && errors.emergency_phone ? "border-red-600" : "border-transparent"}`}
												type="text"
											/>
										</div>
									</div>
									{touched && touched.emergency_phone && errors && errors.emergency_phone && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.emergency_phone}</span>}
								</div>
							</div>
						</div>
						{/* Workers committee */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="workers_committee">
										הרשמה דרך ועד עובדים
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select
										id="workers_committee"
										{...formik.getFieldProps("workers_committee")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.workers_committee && errors && errors.workers_committee ? "border-red-600" : "border-transparent"}`}
										onChange={(e) => {
											const { value } = e.target;

											if (value === "false") {
												formik.setFieldValue("workers_committee", false);
												formik.setFieldValue("workers_committee_id", null);
											} else if (value === "true") {
												formik.setFieldValue("workers_committee", true);
												formik.setFieldValue("workers_committee_id", workers_committees[0].id);
											} else {
												formik.handleChange(e);
											}
										}}>
										<option value={false}>לא</option>
										<option value={true}>כן</option>
									</select>
									{touched && touched.workers_committee && errors && errors.workers_committee && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.workers_committee}</span>}
								</div>
							</div>
						</div>
						{/* Workers committee ID */}
						{formik.values && formik.values.workers_committee && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="workers_committee_id">
											ועד עובדים
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<select
											id="workers_committee_id"
											{...formik.getFieldProps("workers_committee_id")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.workers_committee_id && errors && errors.workers_committee_id ? "border-red-600" : "border-transparent"}`}>
											{workers_committees &&
												workers_committees.map((item) => {
													return (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													);
												})}
										</select>
										{touched && touched.workers_committee_id && errors && errors.workers_committee_id && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.workers_committee_id}</span>}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="flex justify-between mt-8">
					<Button
						onClick={() => {
							setRegisterData({
								...registerData,
								step: step - 1,
							});
						}}
						disabled={isLoading || isSubmitting}>
						חזור
					</Button>
					<Button type="submit" disabled={isLoading || isSubmitting}>
						{isSubmitting ? "שומר..." : "המשך"}
					</Button>
				</div>
			</form>
		</div>
	);
}
