import * as Yup from "yup";

import { phonePrefixes } from "./phonePrefixes";

export const studentInformationValidationSchema = Yup.object({
	first_name: Yup.string().required("שדה חובה"),
	last_name: Yup.string().required("שדה חובה"),
	grade: Yup.number().required("שדה חובה"),
	class_number: Yup.number().required("שדה חובה"),
	gov_id: Yup.string().required("שדה חובה").length(9, "תעודת זהות חייבת להיות בעלת 9 ספרות"),
	birthdate: Yup.date().required("שדה חובה"),
	address: Yup.string().required("שדה חובה"),
	shirt_size: Yup.string().required("שדה חובה").equals(["10", "12", "14", "16", "18", "S", "M", "L", "XL"], "ערך לא תקין"),
	gender: Yup.string().required("שדה חובה").equals(["male", "female"], "המין הנבחר לא תקין"),
	hmo: Yup.string().required("שדה חובה"),
});

export const contactInformationValidationSchema = Yup.object({
	mother_name: Yup.string().required("שדה חובה"),
	// father_name: Yup.string().required("שדה חובה"),
	father_name: Yup.string().nullable(),
	email: Yup.string().email("כתובת מייל לא תקינה").required("שדה חובה"),
	mother_phone_prefix: Yup.string().required("שדה חובה").oneOf(phonePrefixes, "ערך לא תקין"),
	mother_phone: Yup.string()
		.required("שדה חובה")
		.length(7, "מספר הטלפון צריך להיות בדיוק 7 ספרות, ללא קידומת")
		.matches(/^[0-9]+$/, "מספר הטלפון יכול להכיל רק ספרות"),
	father_phone_prefix: Yup.string()
		.nullable()
		.oneOf([...phonePrefixes, "-"], "ערך לא תקין"),
	father_phone: Yup.string().when("father_phone_prefix", {
		is: (val) => val !== "-" && val !== "" && val !== null && val !== undefined,
		then: (schema) =>
			schema
				.required("שדה חובה")
				.length(7, "מספר הטלפון צריך להיות בדיוק 7 ספרות, ללא קידומת")
				.matches(/^[0-9]+$/, "מספר הטלפון יכול להכיל רק ספרות"),
		otherwise: (schema) => schema.notRequired().nullable(),
	}),
	emergency_name: Yup.string().required("שדה חובה"),
	emergency_closeness: Yup.string().required("שדה חובה"),
	emergency_phone_prefix: Yup.string().required("שדה חובה").oneOf(phonePrefixes, "ערך לא תקין"),
	emergency_phone: Yup.string()
		.required("שדה חובה")
		.length(7, "מספר הטלפון צריך להיות בדיוק 7 ספרות, ללא קידומת")
		.matches(/^[0-9]+$/, "מספר הטלפון יכול להכיל רק ספרות"),
	workers_committee: Yup.boolean(),
	workers_committee_id: Yup.number().when("workers_committee", {
		is: true,
		then: (schema) => schema.required("עליך לבחור ועד עובדים"),
		otherwise: (schema) => schema.notRequired(),
	}),
});

export const pickupInformationValidationSchema = Yup.object({
	pickup_city: Yup.string().required("שדה חובה"),
	pickup_school: Yup.string().required("שדה חובה"),
	pickup_type: Yup.string().required("שדה חובה"),
	pickup_contact_name: Yup.string().when("pickup_type", {
		is: "יאסף בליווי אדם מבוגר מטעם המשפחה",
		then: () => Yup.string().required("שדה חובה"),
	}),
	pickup_contact_phone: Yup.string().when("pickup_type", {
		is: "יאסף בליווי אדם מבוגר מטעם המשפחה",
		then: () => Yup.string().required("שדה חובה").length(10, "מספר טלפון חייב להיות בעל 10 ספרות"),
	}),
	pickup_contact_closeness: Yup.string().when("pickup_type", {
		is: "יאסף בליווי אדם מבוגר מטעם המשפחה",
		then: () => Yup.string().required("שדה חובה"),
	}),
	pickup_agree: Yup.boolean().required("שדה חובה").oneOf([true], "עלייך לאשר את נקודת האיסוף"),
	comment: Yup.string(),
});

export const healthInformationValidationSchema = Yup.object().shape({
	healthy: Yup.boolean().required("שדה חובה"),
	has_sensitivities: Yup.boolean().required("שדה חובה"),
	sensitivities: Yup.string().when("has_sensitivities", {
		is: true,
		then: () => Yup.string().required("שדה חובה"),
	}),
	risky_sensitivities: Yup.string().when("has_sensitivities", {
		is: true,
		then: () => Yup.string().required("שדה חובה"),
	}),
	can_swim: Yup.boolean().required("שדה חובה"),
	contact_name: Yup.string().required("שדה חובה"),
	contact_gov_id: Yup.string().required("שדה חובה").min(9, "תעודת זהות לא תקינה").max(9, "תעודת זהות לא תקינה"),
	contact_phone_prefix: Yup.string().required("שדה חובה").oneOf(phonePrefixes, "ערך לא תקין"),
	contact_phone: Yup.string()
		.required("שדה חובה")
		.length(7, "מספר טלפון לא תקין")
		.matches(/^[0-9]+$/, "מספר טלפון לא תקין. יש להקליד ספרות בלבד."),
	terms_agree: Yup.boolean().required("שדה חובה").equals([true], "שדה חובה"),
});
