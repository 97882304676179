import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "../../Button";
import { useRegistrationForm } from "../core/RegistrationFormProvider";
import { RegistrationFormIndicator } from "../RegistrationFormIndicator";
import { pickupInformationValidationSchema as validationSchema } from "../../../utils/validationSchemas";

export default function PickupInformationTab() {
	const { isLoading, cities, registerData, setRegisterData } = useRegistrationForm();
	const { step } = registerData || {};

	const onSubmit = (values) => {
		setRegisterData({
			...registerData,
			pickup_information: {
				...values,
			},
			step: step + 1,
		});
	};

	const formik = useFormik({
		initialValues: {
			pickup_city: "",
			pickup_school: "",
			pickup_type: "",
			pickup_contact_name: "",
			pickup_contact_phone: "",
			pickup_contact_closeness: "",
			pickup_agree: false,
			comment: "",
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	});

	useEffect(() => {
		if (!registerData) {
			return;
		}

		const currentValues = registerData?.pickup_information;

		/**
		 * Set the form values from the saved data, but only fields that exist in the initial values.
		 */
		let newValues = {};
		for (let key in formik.initialValues) {
			if (currentValues && currentValues.hasOwnProperty(key)) {
				newValues[key] = currentValues[key];
			} else {
				newValues[key] = formik.initialValues[key];
			}
		}

		formik.setValues(newValues);
	}, [registerData]);

	let selectedCity = registerData && registerData.pickup_city;
	let selectedCityData = ((selectedCity && cities) || []).filter((c) => c.id === parseInt(selectedCity)).shift();
	let availableSchools = [];

	if (selectedCityData && selectedCityData.schools) {
		availableSchools = selectedCityData.schools;
	}

	if (!cities) {
		return null;
	}

	const { isSubmitting, touched, errors, values } = formik;

	console.log("values", values);
	console.log("errors", errors);

	let schools = [];

	if (values && values.pickup_city) {
		let city = cities.filter((c) => c.id === parseInt(values.pickup_city)).shift();

		if (city && city.schools) {
			schools = city.schools;
		}
	}

	return (
		<div className="max-w-5xl w-full mx-auto my-auto pt-20 pb-5 px-4 md:px-10 text-center rounded-xl bg-opacity-50 bg-white text-white">
			<RegistrationFormIndicator />
			<form onSubmit={formik.handleSubmit} aria-disabled={isLoading || isSubmitting}>
				<div className="grid md:grid-cols-2 md:gap-8 text-right mx-auto text-md w-full">
					<div className="md:col-span-2">
						<div className="text-center">
							<span className="font-bold">תחנת איסוף</span>
						</div>
					</div>

					<div className="col-span-1">
						{/* Pickup city */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="pickup_city">
										עיר
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select id="pickup_city" {...formik.getFieldProps("pickup_city")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_city && errors && errors.pickup_city ? "border-red-600" : "border-transparent"}`}>
										<option value="">בחר/י את העיר שממנה נאסוף את {registerData.first_name}</option>
										{cities
											.sort((a, b) => {
												const letters = "אבגדהוזחטיכלמנסעפצקרשת";

												const aIndex = letters.indexOf(a.name[0]);
												const bIndex = letters.indexOf(b.name[0]);

												return aIndex - bIndex;
											})
											.map((city) => {
												return (
													<option key={city.id} value={city.id}>
														{city.name}
													</option>
												);
											})}
									</select>
									{touched && touched.pickup_city && errors && errors.pickup_city && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_city}</span>}
								</div>
							</div>
						</div>
						{/* Pickup school */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="pickup_school">
										בית ספר
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select
										id="pickup_school"
										{...formik.getFieldProps("pickup_school")}
										disabled={isLoading || isSubmitting}
										className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_school && errors && errors.pickup_school ? "border-red-600" : "border-transparent"}`}>
										<option value="">בחר/י את בית הספר שממנו נאסוף את {registerData.first_name}</option>
										{schools
											.sort((a, b) => {
												const letters = "אבגדהוזחטיכלמנסעפצקרשת";

												const aIndex = letters.indexOf(a.name[0]);
												const bIndex = letters.indexOf(b.name[0]);

												return aIndex - bIndex;
											})
											.map((item) => {
												return (
													<option key={item.id} value={item.id}>
														{item.name}
													</option>
												);
											})}
									</select>
									{touched && touched.pickup_school && errors && errors.pickup_school && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_school}</span>}
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-1">
						{/* Pickup type */}
						<div className="mb-4 flex gap-4">
							<div className="w-full md:flex items-center gap-2">
								<div className="w-full md:w-1/5 pb-4">
									<label className="font-semibold" htmlFor="pickup_type">
										איסוף הילד/ה
									</label>
								</div>
								<div className="relative w-full md:w-4/5 pb-4">
									<select id="pickup_type" {...formik.getFieldProps("pickup_type")} disabled={isLoading || isSubmitting} className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_type && errors && errors.pickup_type ? "border-red-600" : "border-transparent"}`}>
										<option value="">בחר/י את אופן האיסוף</option>
										<option value="יאסף בליווי אדם מבוגר מטעם המשפחה">יאסף בליווי אדם מבוגר מטעם המשפחה</option>
										<option value="ילדי חוזר הביתה בכוחות עצמו">ילדי חוזר הביתה בכוחות עצמו</option>
									</select>
									{touched && touched.pickup_type && errors && errors.pickup_type && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_type}</span>}
								</div>
							</div>
						</div>
						{/* Pickup contact name */}
						{values && values.pickup_type === "יאסף בליווי אדם מבוגר מטעם המשפחה" && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="pickup_contact_name">
											שם האדם האוסף
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<input
											id="pickup_contact_name"
											{...formik.getFieldProps("pickup_contact_name")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_contact_name && errors && errors.pickup_contact_name ? "border-red-600" : "border-transparent"}`}
											type="text"
										/>
										{touched && touched.pickup_contact_name && errors && errors.pickup_contact_name && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_contact_name}</span>}
									</div>
								</div>
							</div>
						)}
						{/* Pickup contact closeness */}
						{values && values.pickup_type === "יאסף בליווי אדם מבוגר מטעם המשפחה" && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="pickup_contact_closeness">
											קרבת האדם האוסף
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<input
											id="pickup_contact_closeness"
											{...formik.getFieldProps("pickup_contact_closeness")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_contact_closeness && errors && errors.pickup_contact_closeness ? "border-red-600" : "border-transparent"}`}
											type="text"
										/>
										{touched && touched.pickup_contact_closeness && errors && errors.pickup_contact_closeness && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_contact_closeness}</span>}
									</div>
								</div>
							</div>
						)}
						{/* Pickup contact phone */}
						{values && values.pickup_type === "יאסף בליווי אדם מבוגר מטעם המשפחה" && (
							<div className="mb-4 flex gap-4">
								<div className="w-full md:flex items-center gap-2">
									<div className="w-full md:w-1/5 pb-4">
										<label className="font-semibold" htmlFor="pickup_contact_phone">
											טלפון האדם האוסף
										</label>
									</div>
									<div className="relative w-full md:w-4/5 pb-4">
										<input
											id="pickup_contact_phone"
											{...formik.getFieldProps("pickup_contact_phone")}
											disabled={isLoading || isSubmitting}
											className={`px-4 py-3 rounded-2xl w-full text-black text-sm border-2 ${touched && touched.pickup_contact_phone && errors && errors.pickup_contact_phone ? "border-red-600" : "border-transparent"}`}
											type="text"
										/>
										{touched && touched.pickup_contact_phone && errors && errors.pickup_contact_phone && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_contact_phone}</span>}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="mt-0 text-right">
					<div className={`w-full text-black bg-opacity-20 p-7 rounded-3xl text-center ${values && values.pickup_agree ? "bg-green-400 border border-green-700" : "bg-red-400 border border-red-700"}`}>
						<strong className={`${values && values.pickup_agree ? "text-green-600" : "text-black"}`}>
							לתשומת ליבך: זוהי נקודת האיסוף של ילדך ולא ניתן לשנות אותה לאחר הבחירה.
							<br />
							על מנת להירשם עם חברים נוספים, על החברים לסמן נקודת איסוף זהה.
						</strong>
					</div>
					<div className="mt-2">
						<div className="relative flex items-center pb-4">
							<label className="block w-full text-right" htmlFor="pickup_agree">
								<input id="pickup_agree" {...formik.getFieldProps("pickup_agree")} disabled={isLoading || isSubmitting} type="checkbox" className="mr-2" checked={values?.pickup_agree} />
								<span>אני מאשר/ת את נקודת האיסוף שבחרתי</span>
							</label>
							{touched && touched.pickup_agree && errors && errors.pickup_agree && <span className="block absolute bottom-0 right-0 text-xs leading-none text-red-700">{errors.pickup_agree}</span>}
						</div>
					</div>
				</div>
				<div className="mt-6 text-right">
					<label className="font-semibold text-right" htmlFor="comment">
						הערות נוספות
					</label>
					<div className="mt-2">
						<textarea id="comment" {...formik.getFieldProps("comment")} className="px-4 py-3 h-32 rounded-2xl w-full text-black text-sm"></textarea>
					</div>
					<span className="block text-sm font-bold text-black">* מומלץ לרשום את שמות החברים איתם תרצו שילדכם יהיה באותו האוטובוס. נעשה את מיטב מאמצינו.</span>
				</div>
				<div className="flex justify-between mt-8">
					<Button
						onClick={() => {
							setRegisterData({
								...registerData,
								step: step - 1,
							});
						}}
						disabled={isLoading || isSubmitting}>
						חזור
					</Button>
					<Button type="submit" disabled={isLoading || isSubmitting}>
						{isSubmitting ? "שומר..." : "המשך"}
					</Button>
				</div>
			</form>
		</div>
	);
}
