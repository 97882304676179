import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { GetOrder } from "../../../services/OrderService";

const OrderGatewayContext = createContext();

export const OrderGatewayProvider = ({ children }) => {
	const { orderKey } = useParams();

	const {
		isLoading,
		isError,
		data: order,
		refetch,
	} = useQuery(
		"order",
		async () => {
			return GetOrder(orderKey);
		},
		{
			enabled: !!orderKey,
			refetchOnWindowFocus: false,
		}
	);

	return <OrderGatewayContext.Provider value={{ isLoading, isError, order, refetch }}>{children}</OrderGatewayContext.Provider>;
};

export const useOrderGateway = () => useContext(OrderGatewayContext);
